'use client'

import { ResponsiveImageBgLoading } from '@component/imageBgLoading'
import classNames from 'classnames'
import { assign } from 'lodash-es'
import { useMemo } from 'react'
import { HomeSearchContext, useHomeSearchState } from '../context'

export default function HomeBase({
  image,
  mImage,
  alt,
  children,
  home = false
}) {

  return (
    <div className='relative px-container-pd min-w-0 sm:min-h-[550px] sm:flex sm:flex-col sm:items-center sm:justify-center min-h-[550px]'>
      <ResponsiveImageBgLoading
        src={image}
        mobileSrc={mImage}
        className='w-full h-full object-cover object-bottom pointer-events-none absolute left-0 top-0 -z-[1] sm:object-center'
        alt={alt}
        priority
        width={1024}
        height={550}
        mobileWidth={375}
        mobileHeight={550}
        useLoading={false}
      />
      {children}
    </div>
  )
}

export function SearchWrap({
  title,
  sub,
  width = 900,
  bgWhite = false,
  oneSuburb = false,
  searchType,
  top,
  children,
  recentSuburb = true
}) {

  const state = useHomeSearchState(oneSuburb, recentSuburb, searchType)

  return (
    <div className={classNames('max-w-full h-full flex-1 min-h-0 flex flex-col before:h-30 sm:before:h-auto', { 'after:flex-1 sm:before:flex-1': !top, 'after:h-28 sm:after:h-80': top })}>
      {
        top && (
          <div className='flex-1 min-h-0 flex items-center mb-20'>{top}</div>
        )
      }
      <div className={classNames('max-w-full mx-auto', bgWhite ? 'bg-white p-14 rounded sm:p-32' : 'text-white')} style={{ width }}>
        {title && <h1 className='text-2xl text-center sm:text-4xl'>{title}</h1>}
        {sub && <h4 className='text-sm max-w-[660px] mx-auto font-extralight mt-8 text-center sm:text-base'>{sub}</h4>}
        <div className='mt-16 text-font sm:mt-24'>
          <HomeSearchContext.Provider value={state}>
            {children}
          </HomeSearchContext.Provider>
        </div>
      </div>
    </div>
  )
}

export function SearchWrapNew({
  title,
  sub,
  width = 900,
  oneSuburb = false,
  searchType,
  children,
  recentSuburb = true,
  type,
  setType
}) {

  const state = useHomeSearchState(oneSuburb, recentSuburb, searchType)
  const stateData = useMemo(() => assign(state, { type, setType }), [state, type, setType])
  return (
    <div className='max-w-full h-full flex-1 min-h-0 flex flex-col before:h-30 sm:before:h-auto after:flex-1 sm:before:flex-1'>
      <div className='max-w-full mx-auto text-white' style={{ width }}>
        {title && <h1 className='text-2xl text-center font-bold sm:text-5xl'>{title}</h1>}
        {sub && <h4 className='text-xs max-w-570 mx-auto font-extralight mt-8 text-center sm:text-base'>{sub}</h4>}
        <div className='mt-16 text-font sm:mt-40'>
          <HomeSearchContext.Provider value={stateData}>
            {children}
          </HomeSearchContext.Provider>
        </div>
      </div>
    </div>
  )
}